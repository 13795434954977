import {
  Button,
  ColumnLayout,
  Container,
  FormField,
  Header,
  SpaceBetween,
  StatusIndicator,
  Table,
  Textarea,
} from '@cloudscape-design/components';
import {
  collection,
  doc,
  getFirestore,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useFirestoreCollectionData,
} from 'reactfire';

import NotificationsContext from '../../features/notifications';
import parseTime from '../HMI/components/parseTime';
import { ProcessDataContext } from './context';

function ExpandableParametersTable({ rows, plcConfig, instronMetadata }) {
  const [expandedItems, setExpandedItems] = useState([]);

  const items = useMemo(() => {
    const retItems = rows.map((row) => ({
      variable: row.header,
      value: '',
      children: row.values.map((item) => {
        const { handle, type, label } = item;
        let value = plcConfig[handle];
        if (value === undefined) {
          return null;
        }
        if (type === 'TIME') {
          value = parseTime(value);
        }
        return {
          variable: label,
          handle,
          value,
        };
      }).filter(Boolean),
    }));
    if (instronMetadata) {
      retItems.push({
        variable: 'Instron Data',
        children: Object.entries(instronMetadata).map(([key, value]) => ({
          variable: key,
          value,
        })),
      });
    }
    return retItems;
  }, [rows, plcConfig, instronMetadata]);

  return (
    <Table
      header={<Header>HMI Parameters</Header>}
      expandableRows={{
        getItemChildren: (item) => item.children,
        isItemExpandable: (item) => Boolean(item.children),
        expandedItems,
        onExpandableItemToggle: ({ detail }) => setExpandedItems((prev) => {
          const next = new Set(
            (prev ?? []).map((item) => item.variable),
          );
          if (detail.expanded) {
            next.add(detail.item.variable);
          } else {
            next.delete(detail.item.variable);
          }
          return [...next].map((variable) => ({ variable }));
        }),
      }}
      expandedItems={expandedItems}
      columnDefinitions={[
        {
          id: 'variable',
          header: 'Variable',
          cell: (e) => e.variable,
          isRowHeader: true,
          width: '30%',
          maxWidth: '30%',
        },
        {
          id: 'handle',
          header: 'Handle',
          cell: (e) => e.handle,
          isRowHeader: true,
          width: '40%',
          maxWidth: '40%',
        },
        {
          id: 'value',
          header: 'Value',
          cell: (e) => e.value,
          width: '30%',
          maxWidth: '30%',
        },
      ]}
      items={items}
      trackBy="variable"
    />
  );
}

ExpandableParametersTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({
    header: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({
      handle: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })),
  })).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  plcConfig: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  instronMetadata: PropTypes.object,
};

ExpandableParametersTable.defaultProps = {
  instronMetadata: null,
};

function HMIParameters() {
  const { session, instronData } = useContext(ProcessDataContext);

  const { status, data: configSnapshot } = useFirestoreCollectionData(
    query(
      collection(getFirestore(), 'process_configs'),
      where('version', '==', session.plcConfig.version),
      where('processType', '==', session.process),
    ),
  );

  const { config, configErr } = useMemo(() => {
    if (!configSnapshot || configSnapshot.length === 0) {
      return { configErr: `No ${session.process} config found with a matching version ${session?.plcConfig?.version}. Showing raw data.` };
    }
    if (configSnapshot.length > 1) {
      return { configErr: `More than one ${session.process} config found with a matching version ${session.plcConfig.version}. Showing raw data.` };
    }

    const configData = { ...configSnapshot[0] };
    const sections = [];
    const sectionIndices = new Map();
    configData.handles.forEach((handle) => {
      if (!sectionIndices.has(handle.section)) {
        sectionIndices.set(handle.section, sections.length);
        sections.push({ header: handle.section, values: [] });
      }
    });
    configData.handles.forEach((handle) => {
      const sectionIndex = sectionIndices.get(handle.section);
      sections[sectionIndex].values.push(handle);
    });
    return { config: { ...configData, rows: sections } };
  }, [session, configSnapshot]);

  const { addNotification, removeNotification } = useContext(NotificationsContext);
  useEffect(() => {
    if (configErr) {
      addNotification({
        header: 'Error',
        type: 'error',
        content: configErr,
        dismissible: false,
        id: `${session.id}-configErr`,
      });
    } else {
      removeNotification(`${session.id}-configErr`);
    }
  }, [configErr]);

  if (status === 'loading') {
    return (
      <Container header={<Header>Parameters</Header>}>
        <StatusIndicator type="loading">Loading...</StatusIndicator>
      </Container>
    );
  }

  if (config?.rows) {
    return (
      <ExpandableParametersTable
        rows={config.rows}
        plcConfig={session.plcConfig}
        instronMetadata={instronData?.metadata}
      />
    );
  }
  return (
    <Table
      header={<Header>HMI Parameters</Header>}
      columnDefinitions={[
        {
          id: 'system',
          header: 'System',
          cell: (e) => e.system,
          isRowHeader: true,
        },
        {
          id: 'handle',
          header: 'Variable',
          cell: (e) => e.handle,
          isRowHeader: true,
        },
        {
          id: 'value',
          header: 'Value',
          cell: (e) => e.value,
        },
      ]}
      items={[
        ...Object.keys(session.plcConfig || {}).map((key) => (
          { system: 'PLC', handle: key, value: session.plcConfig[key] }
        )),
        ...Object.keys(instronData?.metadata || {}).map((key) => (
          { system: 'Instron', handle: key, value: instronData.metadata[key] }
        )),
      ]}
    />
  );
}

HMIParameters.propTypes = {
  session: PropTypes.shape({
    id: PropTypes.string,
    plcConfig: PropTypes.shape({
      version: PropTypes.string,
    }),
    process: PropTypes.string,
  }).isRequired,
};

function Overview() {
  const { session } = useContext(ProcessDataContext);

  const initialValues = {
    hypothesis: session.hypothesis,
    observations: session.observations,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={false}
      onSubmit={async (values) => {
        await updateDoc(doc(getFirestore(), 'process_logs', session.id), {
          hypothesis: values.hypothesis,
          observations: values.observations,
        });
      }}
    >
      {({
        values, handleSubmit, setFieldValue, isSubmitting, dirty,
      }) => (
        <SpaceBetween size="l">
          <Container header={(
            <Header
              actions={(
                <Button
                  variant="primary"
                  disabled={!dirty || isSubmitting}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              )}
            >
              {`${session.project} - ${session.number}`}
            </Header>
          )}
          >
            <ColumnLayout columns={2}>
              <FormField label="Hypothesis" stretch>
                <Textarea
                  value={values.hypothesis}
                  rows={3}
                  onChange={({ detail }) => { setFieldValue('hypothesis', detail.value); }}
                />
              </FormField>
              <FormField label="Observations" stretch>
                <Textarea
                  value={values.observations}
                  rows={3}
                  onChange={({ detail }) => { setFieldValue('observations', detail.value); }}
                />
              </FormField>
            </ColumnLayout>
          </Container>
          {!!session?.plcConfig?.version && !!session?.process ? (
            <HMIParameters session={session} />
          ) : null}
        </SpaceBetween>
      )}
    </Formik>
  );
}

export default Overview;
