import { doc, getFirestore } from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestoreDocData } from 'reactfire';

const NONEXISTENT = 'nonexistent';

const usePart = (partId) => {
  const { data: part, status } = useFirestoreDocData(doc(getFirestore(), 'parts', partId || NONEXISTENT), { idField: 'id' });
  return useMemo(() => ({
    part: part || {},
    status,
  }), [part, status]);
};

const usePartAndQuote = (partId) => {
  const { part, status: partQueryStatus } = usePart(partId);
  const { quote: quoteId } = useMemo(() => {
    if (part) {
      return part;
    }
    return {};
  });
  const { data: quote, status: quoteQueryStatus } = useFirestoreDocData(doc(getFirestore(), 'quotes', quoteId || NONEXISTENT), { idField: 'id' });

  if (!partId) {
    return {
      part: {},
      quote: {},
      status: NONEXISTENT,
    };
  }

  const status = useMemo(() => {
    let retStatus = 'success';
    if (partQueryStatus === 'loading' || quoteQueryStatus === 'loading') {
      retStatus = 'loading';
    }
    if (partQueryStatus === 'error' || quoteQueryStatus === 'error') {
      retStatus = 'error';
    }
    return retStatus;
  });

  return useMemo(() => ({
    part: part || {},
    quote: quote || {},
    status,
  }), [part, quote, status]);
};

export default usePartAndQuote;

export { usePart };
