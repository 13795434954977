import {
  Button,
  Checkbox,
  ColumnLayout,
  Container,
  ContentLayout,
  Flashbar,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
  Spinner,
  Textarea,
} from '@cloudscape-design/components';
import {
  doc,
  getFirestore,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import React, {
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  useFirestoreDocData,
  useUser,
} from 'reactfire';

import Changelog from './changelog';
import ProcessMultiselect from './processMultiselect';

function TravelerPage() {
  const [error, setError] = useState(null);

  const { data: user } = useUser();
  const { travelerId } = useParams();
  const { data: traveler, status: travelerStatus, error: travelerError } = useFirestoreDocData(doc(getFirestore(), 'travelers', travelerId));
  const { data: schema, status: schemaStatus, error: schemaError } = useFirestoreDocData(doc(getFirestore(), '_rowy_', 'settings', 'schema', 'partTravelers'));

  const [moldedPartInspectedOptions, moldedPartInspectedOptionsError] = useMemo(() => {
    if (schemaStatus === 'error') {
      return [[], schemaError];
    }
    if (schemaStatus === 'success') {
      const options = schema?.columns?.moldedPartInspected?.config?.options || [];
      if (options.length === 0) {
        return [[], 'Error loading options. Try refreshing the page.'];
      }
      return [options.map((option) => ({
        value: option,
        label: option,
      })), null];
    }
    return [[], null];
  }, [schema, schemaStatus, schemaError]);
  const [bondedPartInspectedOptions, bondedPartInspectedOptionsError] = useMemo(() => {
    if (schemaStatus === 'error') {
      return [[], schemaError];
    }
    if (schemaStatus === 'success') {
      const options = schema?.columns?.bondedPartInspected?.config?.options || [];
      if (options.length === 0) {
        return [[], 'Error loading options. Try refreshing the page.'];
      }
      return [options.map((option) => ({
        value: option,
        label: option,
      })), null];
    }
    return [[], null];
  }, [schema, schemaStatus, schemaError]);

  const initialValues = useMemo(() => {
    if (!traveler || travelerStatus !== 'success') {
      return {};
    }
    return {
      ...traveler,
      moldRunId: traveler.moldRunId,
      bondRunId: traveler.bondRunId,
      moldedPartInspected: traveler.moldedPartInspected ? {
        value: traveler.moldedPartInspected,
        label: traveler.moldedPartInspected,
      } : null,
      bondedPartInspected: traveler.bondedPartInspected ? {
        value: traveler.bondedPartInspected,
        label: traveler.bondedPartInspected,
      } : null,
    };
  }, [traveler, travelerStatus]);

  if (travelerStatus === 'loading') {
    return (
      <ContentLayout
        header={(
          <Header variant="h2">
            Edit Traveler
          </Header>
                )}
      >
        <Container>
          {' '}
          <Spinner />
        </Container>
      </ContentLayout>
    );
  }

  if (travelerStatus === 'error') {
    return (
      <ContentLayout
        header={(
          <Header variant="h2">
            Edit Traveler
          </Header>
                 )}
      >
        <Container>
          <Flashbar
            items={[{
              header: 'Error loading traveler',
              content: travelerError,
              type: 'error',
            }]}
          />
        </Container>
      </ContentLayout>
    );
  }

  if (!traveler) {
    return (
      <ContentLayout
        header={(
          <Header variant="h2">
            {`Edit Traveler ${travelerId}`}
          </Header>
                 )}
      >
        <Container>
          <Flashbar
            items={[{
              header: 'Traveler not found',
              content: `No traveler found with ID ${travelerId}. If you have a traveler sticker with this id, it might mean the traveler hasn't been used yet. Try starting a forming run with this travler!`,
              type: 'warning',
            }]}
          />
        </Container>
      </ContentLayout>
    );
  }

  return (
    <ContentLayout
      header={(
        <Header variant="h2">
          {`Edit Traveler ${travelerId}`}
        </Header>
                 )}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          setError(null);
          try {
            await updateDoc(doc(getFirestore(), 'travelers', travelerId), {
              project: values.project || '',
              tool: values.tool || '',
              moldRunId: values.moldRunId,
              moldedPartInspected: values.moldedPartInspected?.value || 'Not inspected',
              secondariesMachined: values.secondariesMachined || false,
              bondRunId: values.bondRunId,
              bondedPartInspected: values.bondedPartInspected?.value || 'Not inspected',
              componentsWelded: values.componentsWelded || false,
              passedFinalInspection: values.passedFinalInspection || false,
              shipped: values.shipped || false,
              notes: values.notes || '',
              _updatedBy: {
                email: user.email,
                timestamp: serverTimestamp(),
                uid: user.uid,
              },
            });
          } catch (e) {
            setError(e.message);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          handleSubmit, setFieldValue, values, isSubmitting, dirty, errors,
        }) => (
          <Container>
            <SpaceBetween direction="vertical" size="l">
              {error ? (
                <Flashbar
                  items={[{
                    header: 'Error saving traveler',
                    content: error,
                    type: 'error',
                  }]}
                />
              ) : null}
              <ColumnLayout columns={2}>
                <FormField
                  label="Project"
                  errorText={errors.project}
                  stretch
                >
                  <Input
                    value={values.project}
                    onChange={({ detail }) => setFieldValue('project', detail.value)}
                  />
                </FormField>
                <FormField
                  label="Tool"
                  errorText={errors.tool}
                  stretch
                >
                  <Input
                    value={values.tool}
                    onChange={({ detail }) => setFieldValue('tool', detail.value)}
                  />
                </FormField>
              </ColumnLayout>
              <ColumnLayout columns={2}>
                <FormField
                  label="Mold Run ID"
                  errorText={errors.moldRunId}
                  stretch
                >
                  <ProcessMultiselect
                    projectId={values.project}
                    process="forming"
                    selectedOptions={values.moldRunId}
                    setValue={(moldRunId) => setFieldValue('moldRunId', moldRunId)}
                  />
                </FormField>
                <FormField
                  label="Molded Part Inspected"
                  errorText={errors.moldedPartInspected}
                  stretch
                >
                  <Select
                    selectedOption={values.moldedPartInspected}
                    onChange={({ detail }) => setFieldValue('moldedPartInspected', detail.selectedOption)}
                    statusType={moldedPartInspectedOptionsError ? 'error' : schemaStatus}
                    recoveryText={moldedPartInspectedOptionsError}
                    options={moldedPartInspectedOptions}
                  />
                </FormField>
              </ColumnLayout>
              <FormField
                label="Secondaries Machined"
                errorText={errors.secondariesMachined}
                stretch
              >
                <Checkbox
                  onChange={({ detail }) => {
                    setFieldValue('secondariesMachined', detail.checked);
                  }}
                  checked={values.secondariesMachined || false}
                >
                  Machining complete
                </Checkbox>
              </FormField>
              <ColumnLayout columns={2}>
                <FormField
                  label="Bond Run ID"
                  errorText={errors.bondRunId}
                  stretch
                >
                  <ProcessMultiselect
                    projectId={values.project}
                    process="bonding"
                    selectedOptions={values.bondRunId}
                    setValue={(bondRunId) => setFieldValue('bondRunId', bondRunId)}
                  />
                </FormField>
                <FormField
                  label="Bonded Part Inspected"
                  errorText={errors.bondedPartInspected}
                  stretch
                >
                  <Select
                    selectedOption={values.bondedPartInspected}
                    onChange={({ detail }) => setFieldValue('bondedPartInspected', detail.selectedOption)}
                    statusType={bondedPartInspectedOptionsError ? 'error' : schemaStatus}
                    recoveryText={bondedPartInspectedOptionsError}
                    options={bondedPartInspectedOptions}
                  />
                </FormField>
              </ColumnLayout>
              <FormField
                label="Components Wedled"
                errorText={errors.componentsWelded}
                stretch
              >
                <Checkbox
                  onChange={({ detail }) => {
                    setFieldValue('componentsWelded', detail.checked);
                  }}
                  checked={values.componentsWelded || false}
                >
                  Welding complete
                </Checkbox>
              </FormField>
              <FormField
                label="Passed final inspection"
                errorText={errors.passedFinalInspection}
                stretch
              >
                <Checkbox
                  onChange={({ detail }) => {
                    setFieldValue('passedFinalInspection', detail.checked);
                  }}
                  checked={values.passedFinalInspection || false}
                >
                  Passed
                </Checkbox>
              </FormField>
              <FormField
                label="Shipped"
                errorText={errors.shipped}
                stretch
              >
                <Checkbox
                  onChange={({ detail }) => {
                    setFieldValue('shipped', detail.checked);
                  }}
                  checked={values.shipped || false}
                >
                  Shipped
                </Checkbox>
              </FormField>
              <FormField
                label="Notes"
                errorText={errors.notes}
                stretch
              >
                <Textarea
                  value={values.notes}
                  onChange={({ detail }) => setFieldValue('notes', detail.value)}
                />
              </FormField>
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={!dirty}
                loading={isSubmitting}
              >
                Save
              </Button>
            </SpaceBetween>
          </Container>
        )}
      </Formik>
      <Changelog travelerId={travelerId} />
    </ContentLayout>
  );
}

export default TravelerPage;
