import {
  Header,
} from '@cloudscape-design/components';
import {
  collection,
  getFirestore,
  orderBy,
  query,
} from 'firebase/firestore';
import React, { useMemo, useState } from 'react';

import {
  OrderDetail,
  OrderDetailContext,
  OrderList,
} from '../../components/Orders';

function OrderListPage() {
  const [orderId, setOrderId] = useState();
  const orderContextValue = useMemo(() => ({ orderId, setOrderId }), [orderId, setOrderId]);
  const recentOrdersQuery = useMemo(() => query(
    collection(getFirestore(), 'orders'),
    orderBy('orderDate', 'desc'),
  ), []);

  return (
    <OrderDetailContext.Provider value={orderContextValue}>
      <OrderDetail />
      <OrderList
        header={<Header variant="awsui-h1-sticky">Orders</Header>}
        variant="full-page"
        ordersQuery={recentOrdersQuery}
      />
    </OrderDetailContext.Provider>
  );
}

export default OrderListPage;
