import {
  Box,
  FormField,
  SpaceBetween,
  Textarea,
} from '@cloudscape-design/components';
import {
  doc,
  getFirestore,
  updateDoc,
} from 'firebase/firestore';
import React from 'react';
import { useFirestoreDocData } from 'reactfire';

import { usePLCValue } from '../../../features/plc/context';
import { useProgramHandle } from '../hooks/context';

export default function FirebaseSession() {
  const programHandle = useProgramHandle();
  const firebaseSessionId = usePLCValue(`${programHandle}.sFirebaseSessionID`);

  const { data: session } = useFirestoreDocData(doc(getFirestore(), 'process_logs', firebaseSessionId || 'nonexistant'));

  return (
    <SpaceBetween size="m">
      <div>
        <Box variant="awsui-key-label">Project</Box>
        <div>{session ? session.project : 'No active session'}</div>
      </div>
      <div>
        <Box variant="awsui-key-label">Number</Box>
        <div>{session ? session.number : 'No active session'}</div>
      </div>
      <div>
        <Box variant="awsui-key-label">Hypothesis</Box>
        <div>{session ? session.hypothesis : 'No active session'}</div>
      </div>

      <FormField label="Observations">
        <Textarea
          rows={4}
          disabled={!session}
          value={session?.observations}
          onChange={({ detail }) => {
            const { value } = detail;
            updateDoc(doc(getFirestore(), 'process_logs', firebaseSessionId), {
              observations: value,
            });
          }}
        />
      </FormField>
    </SpaceBetween>
  );
}
