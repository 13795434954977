import {
  StatusIndicator,
} from '@cloudscape-design/components';
import {
  collection,
  getFirestore,
  limit,
  orderBy,
  query,
} from 'firebase/firestore';
import moment from 'moment';
import React, { useContext, useMemo } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useFirestoreCollectionData } from 'reactfire';

import { OrderDetailContext } from './detail';

function OrderCalendar() {
  const { setOrderId } = useContext(OrderDetailContext);

  const ordersQuery = query(
    collection(getFirestore(), 'orders'),
    orderBy('orderDate', 'desc'),
    limit(20),
  );
  const { status: orderQueryStatus, data: orders } = useFirestoreCollectionData(ordersQuery, { idField: 'id' });
  const shipmentEvents = useMemo(() => {
    if (orderQueryStatus !== 'success' || !orders) {
      return [];
    }

    const events = [];
    for (let i = 0; i < orders.length; i += 1) {
      const order = orders[i];
      for (let j = 0; j < (order.shipments || []).length; j += 1) {
        const shipment = order.shipments[j];
        events.push({
          id: `${order.id}-${j}`,
          orderId: order.id,
          start: new Date(`${shipment.targetShipDate} 00:00:00`),
          end: new Date(`${shipment.targetShipDate} 00:00:00`),
          shipmentId: shipment.name,
          customer: order.customer,
          order: order.name,
          title: `${order.customer} - ${shipment.name}`,
          allDay: true,
        });
      }
    }
    return events;
  }, [orderQueryStatus, orders]);

  const localizer = momentLocalizer(moment);

  if (orderQueryStatus === 'loading') {
    return <StatusIndicator type="loading">Loading...</StatusIndicator>;
  }

  return (
    <Calendar
      events={shipmentEvents}
      localizer={localizer}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 500 }}
      views={{ month: true }}
      onSelectEvent={(event) => { setOrderId(event.orderId); }}
    />
  );
}

export default OrderCalendar;
