import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  messageSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const FirebaseContext = React.createContext();

function FirebaseContextProvider({ children }) {
  const [auth, setAuth] = useState(null);
  const [user, setUser] = useState(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    initializeApp(firebaseConfig);
    setAuth(getAuth());
    setIsReady(true);
  }, []);

  useEffect(() => {
    if (auth) {
      return onAuthStateChanged(auth, (authUser) => {
        if (authUser) {
          const emailParts = authUser.email.split('@');
          if (emailParts[emailParts.length - 1] === 'parallelfluidics.com') {
            setUser(authUser);
          } else {
            signOut(auth);
          }
        } else {
          setUser(null);
        }
      });
    }
    return () => {};
  }, [auth]);

  const contextValue = useMemo(() => ({ user, isReady }), [user, isReady]);

  return (
    <FirebaseContext.Provider value={contextValue}>
      {children}
    </FirebaseContext.Provider>
  );
}

FirebaseContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default FirebaseContext;

export { FirebaseContextProvider, firebaseConfig };
