import {
  Alert,
  Box,
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
  StatusIndicator,
  TextContent,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React from 'react';

import {
  useProjectStatus,
  useShipDate,
} from './hooks';

function ActiveOrderStatus({
  customerName,
  projectNumber,
  shipDate,
  moldedGoal,
  postmachinedGoal,
  bondedGoal,
  connectorizedGoal,
  minDate,
  notes,
}) {
  const { projectStatus, queryStatus } = useProjectStatus(projectNumber, minDate);
  const shipDateText = useShipDate(shipDate);

  if (queryStatus === 'loading') {
    return (
      <Container header={<Header>{`[${projectNumber}] ${customerName} - ${shipDateText}`}</Header>}>
        <StatusIndicator type="loading">Loading...</StatusIndicator>
      </Container>
    );
  }
  if (queryStatus === 'error') {
    return (
      <Container header={<Header>{`[${projectNumber}] ${customerName} - ${shipDateText}`}</Header>}>
        <StatusIndicator type="error">Error</StatusIndicator>
      </Container>
    );
  }
  return (
    <Container header={<Header>{`[${projectNumber}] ${customerName} - ${shipDateText}`}</Header>}>
      <SpaceBetween direction="vertical" size="m">
        {notes ? (
          <Alert statusIconAriaLabel="Warning" type="warning">
            <TextContent>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: notes }} />
            </TextContent>
          </Alert>
        ) : null}
        <ColumnLayout
          columns={[
            moldedGoal, postmachinedGoal, bondedGoal, connectorizedGoal,
          ].filter((goal) => goal > 0).length}
          variant="text-grid"
          minColumnWidth={170}
        >
          {moldedGoal > 0 ? (
            <SpaceBetween size="m">
              <div>
                <Box variant="awsui-key-label">Molded Parts</Box>
                <Box variant="awsui-value-large">
                  {`${projectStatus.molded.inspected}/${moldedGoal} Inspected`}
                </Box>
              </div>
              <div>
                <Box variant="awsui-key-label">To-be inspected</Box>
                <div>{projectStatus.molded.toBeInspected}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Reject (Cosmetic)</Box>
                <div>{projectStatus.molded.rejectCosmetic}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Reject (Major)</Box>
                <div>{projectStatus.molded.rejectMajor}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Tuning</Box>
                <div>{projectStatus.molded.tuning}</div>
              </div>
            </SpaceBetween>
          ) : null}
          {postmachinedGoal > 0 ? (
            <SpaceBetween size="m">
              <div>
                <Box variant="awsui-key-label">Postmachined Parts</Box>
                <Box variant="awsui-value-large">
                  {`${projectStatus.postmachined.done}/${postmachinedGoal} Done`}
                </Box>
              </div>
              <div>
                <Box variant="awsui-key-label">To be postmachined</Box>
                <div>{projectStatus.postmachined.todo}</div>
              </div>
            </SpaceBetween>
          ) : null}
          {bondedGoal > 0 ? (
            <SpaceBetween size="m">
              <div>
                <Box variant="awsui-key-label">Bonded Parts</Box>
                <Box variant="awsui-value-large">
                  {`${projectStatus.bonded.inspected}/${bondedGoal} Inspected`}
                </Box>
              </div>
              <div>
                <Box variant="awsui-key-label">To-be inspected</Box>
                <div>{projectStatus.bonded.toBeInspected}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Reject (Cosmetic)</Box>
                <div>{projectStatus.bonded.rejectCosmetic}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Reject (Major)</Box>
                <div>{projectStatus.bonded.rejectMajor}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Tuning</Box>
                <div>{projectStatus.bonded.tuning}</div>
              </div>
            </SpaceBetween>
          ) : null}
          {connectorizedGoal > 0 ? (
            <SpaceBetween size="m">
              <div>
                <Box variant="awsui-key-label">Connectorized Parts</Box>
                <Box variant="awsui-value-large">
                  {`${projectStatus.connectorized.done}/${connectorizedGoal} Done`}
                </Box>
              </div>
              <div>
                <Box variant="awsui-key-label">To be connectorized</Box>
                <div>{projectStatus.connectorized.todo}</div>
              </div>
            </SpaceBetween>
          ) : null}
        </ColumnLayout>
      </SpaceBetween>
    </Container>
  );
}

ActiveOrderStatus.propTypes = {
  customerName: PropTypes.string.isRequired,
  projectNumber: PropTypes.string.isRequired,
  shipDate: PropTypes.string.isRequired,
  moldedGoal: PropTypes.number,
  postmachinedGoal: PropTypes.number,
  bondedGoal: PropTypes.number,
  connectorizedGoal: PropTypes.number,
  minDate: PropTypes.string,
  notes: PropTypes.string,
};

ActiveOrderStatus.defaultProps = {
  moldedGoal: 0,
  postmachinedGoal: 0,
  bondedGoal: 0,
  connectorizedGoal: 0,
  minDate: undefined,
  notes: undefined,
};

export default ActiveOrderStatus;
