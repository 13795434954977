import {
  Input,
} from '@cloudscape-design/components';
import {
  getFunctions,
  httpsCallable,
} from 'firebase/functions';
import React, {
  useCallback,
  useContext,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import NotificationsContext from '../../features/notifications';

function OmniSearch() {
  const [input, setInput] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const navigate = useNavigate();
  const { addNotification, removeNotification } = useContext(NotificationsContext);
  const errorId = 'omni-search-error';

  const getUserByEmail = useCallback(async (userEmail) => {
    try {
      setIsSearching(true);
      removeNotification(errorId);
      const functions = getFunctions();
      const getUserIDByEmail = httpsCallable(functions, 'getUserIDByEmail');
      const result = await getUserIDByEmail({ userEmail });
      if (result.data) {
        setInput('');
        navigate(`/user/${result.data}`);
      }
    } catch (err) {
      addNotification({
        type: 'error',
        dismissible: true,
        content: err.message,
        id: errorId,
      });
    }
    setIsSearching(false);
  }, [navigate]);

  return (
    <Input
      placeholder="Search for a user by email address"
      value={input}
      onChange={({ detail }) => { setInput(detail.value); }}
      onKeyDown={({ detail }) => {
        if (detail.key === 'Enter') {
          const trimmedInput = input.trim();
          if (trimmedInput) {
            getUserByEmail(trimmedInput);
          }
        }
      }}
      disabled={isSearching}
    />
  );
}

export default OmniSearch;
