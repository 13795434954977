import {
  Box,
  Button,
  FormField,
  Grid,
  Input,
  Modal,
  SpaceBetween,
} from '@cloudscape-design/components';
import {
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { Formik } from 'formik';
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth, useUser } from 'reactfire';

function Login() {
  const auth = useAuth();
  const { data: user, status } = useUser();

  const location = useLocation();
  const from = useMemo(() => {
    let ret = location.state?.from?.pathname || '/';
    if (location.state?.from?.search) {
      ret += location.state.from.search;
    }
    return ret;
  }, [location]);
  window.from = from;
  window.PhoneAuthProvider = PhoneAuthProvider;
  window.PhoneMultiFactorGenerator = PhoneMultiFactorGenerator;

  // set up reCAPTCHA verifier
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const recaptchaRef = useCallback((node) => {
    if (node !== null) {
      setRecaptchaVerifier(new RecaptchaVerifier(node, { size: 'invisible' }, auth));
    }
  }, []);
  const resolver = useRef();
  window.resolver = resolver;

  if (user) {
    return <Navigate to={from} replace />;
  }

  return (
    <>
      {status === 'success' ? <div ref={recaptchaRef} /> : null}
      <Formik
        initialValues={{
          username: '',
          password: '',
          verificationID: '',
          verificationCode: '',
        }}
        onSubmit={async (values, { setFieldValue, setFieldError, setSubmitting }) => {
          if (!values.verificationCode) {
            try {
              await signInWithEmailAndPassword(auth, values.username, values.password);
            } catch (err) {
              if (err.code === 'auth/multi-factor-auth-required') {
                try {
                  resolver.current = getMultiFactorResolver(auth, err);
                  // Ask user which second factor to use.
                  if (resolver.current.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
                    const phoneInfoOptions = {
                      multiFactorHint: resolver.current.hints[0],
                      session: resolver.current.session,
                    };
                    const phoneAuthProvider = new PhoneAuthProvider(auth);

                    // Send SMS verification code
                    const returnedVerificationID = await phoneAuthProvider.verifyPhoneNumber(
                      phoneInfoOptions,
                      recaptchaVerifier,
                    );

                    // Save verification ID to state.
                    setFieldValue('verificationID', returnedVerificationID);
                  }
                } catch (mfaErr) {
                  setFieldError('username', mfaErr.message);
                }
              } else {
                setFieldError('username', err.message);
              }
            }
            setSubmitting(false);
            return;
          }
          try {
            // Finalize sign-in after the user submits the code.
            const cred = PhoneAuthProvider.credential(
              values.verificationID,
              values.verificationCode,
            );
            const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
            await resolver.current.resolveSignIn(multiFactorAssertion);
          } catch (err) {
            setFieldError('verificationCode', err.message);
          }
          setSubmitting(false);
        }}
      >
        {({
          values, errors, handleSubmit, setFieldValue, isSubmitting,
        }) => {
          window.values = values;
          return (
            <Modal
              size="medium"
              header="Sign in to continue"
              visible
              footer={(
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="m">
                    {values.verificationID ? (
                      <Button
                        variant="secondary"
                        onClick={() => {
                          resolver.current = undefined;
                          setFieldValue('username', '');
                          setFieldValue('password', '');
                          setFieldValue('verificationID', '');
                          setFieldValue('verificationCode', '');
                        }}
                        disabled={isSubmitting}
                      >
                        Reset
                      </Button>
                    ) : null}
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={handleSubmit}
                      loading={isSubmitting}
                    >
                      Login
                    </Button>
                  </SpaceBetween>
                </Box>
            )}
            >
              <Grid
                gridDefinition={values.verificationID
                  ? [{ colspan: 6 }, { colspan: 6 }, { colspan: 12 }]
                  : [{ colspan: 6 }, { colspan: 6 }]}
              >
                <FormField
                  label="Username"
                  errorText={errors.username}
                >
                  <Input
                    value={values.username}
                    onChange={({ detail }) => { setFieldValue('username', detail.value); }}
                    disabled={!!values.verificationID || isSubmitting}
                  />
                </FormField>
                <FormField
                  label="Password"
                  errorText={errors.password}
                >
                  <Input
                    value={values.password}
                    onChange={({ detail }) => { setFieldValue('password', detail.value); }}
                    type="password"
                    disabled={!!values.verificationID || isSubmitting}
                  />
                </FormField>
                {values.verificationID ? (
                  <FormField
                    label="Verification Code"
                    errorText={errors.verificationCode}
                  >
                    <Input
                      value={values.verificationCode}
                      onChange={({ detail }) => { setFieldValue('verificationCode', detail.value); }}
                      disabled={isSubmitting}
                    />
                  </FormField>
                ) : null}
              </Grid>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
}

export default Login;
