import {
  Box,
  Button,
  Container,
  Header,
  SpaceBetween,
  StatusIndicator,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useActiveProjects, useProjectStatus, useShipDate } from './hooks';

function ProjectStatus({
  customerName,
  projectNumber,
  shipDate,
  moldedGoal,
  postmachinedGoal,
  bondedGoal,
  connectorizedGoal,
  minDate,
}) {
  const { projectStatus, queryStatus } = useProjectStatus(projectNumber, minDate);
  const { status, statusText } = useMemo(() => {
    if (queryStatus === 'loading') {
      return { status: queryStatus };
    }
    const {
      molded, postmachined, bonded, connectorized,
    } = projectStatus;
    if (connectorizedGoal) {
      return { status: 'success', statusText: `${connectorized.done}/${connectorizedGoal} Connectorized` };
    }
    if (bondedGoal) {
      return { status: 'success', statusText: `${bonded.inspected}/${bondedGoal} Bonded` };
    }
    if (postmachinedGoal) {
      return { status: 'success', statusText: `${postmachined.done}/${postmachinedGoal} Postmachined` };
    }
    if (moldedGoal) {
      return { status: 'success', statusText: `${molded.inspected}/${moldedGoal} Molded` };
    }
    return { status: 'error' };
  }, [projectStatus, queryStatus]);
  const shipDateText = useShipDate(shipDate);

  return (
    <div>
      <Box variant="awsui-key-label">{`[${projectNumber}] ${customerName} - ${shipDateText}`}</Box>
      {status === 'loading' ? (
        <StatusIndicator type="loading">Loading...</StatusIndicator>
      ) : null}
      {status === 'error' ? (
        <StatusIndicator type="error">Unknown status</StatusIndicator>
      ) : null}
      {status === 'success' ? (
        <Box variant="awsui-value-large">
          {statusText}
        </Box>
      ) : null}
    </div>
  );
}

ProjectStatus.propTypes = {
  customerName: PropTypes.string.isRequired,
  projectNumber: PropTypes.string.isRequired,
  shipDate: PropTypes.string.isRequired,
  moldedGoal: PropTypes.number,
  postmachinedGoal: PropTypes.number,
  bondedGoal: PropTypes.number,
  connectorizedGoal: PropTypes.number,
  minDate: PropTypes.string,
};

ProjectStatus.defaultProps = {
  moldedGoal: 0,
  postmachinedGoal: 0,
  bondedGoal: 0,
  connectorizedGoal: 0,
  minDate: undefined,
};

function Summary() {
  const activeProjects = useActiveProjects(5);
  const navigate = useNavigate();

  return (
    <Container
      fitHeight
      header={(
        <Header actions={<Button variant="normal" onClick={() => { navigate('/orders/active'); }}>See all active orders</Button>}>
          Active orders
        </Header>
    )}
    >
      <SpaceBetween direction="vertical" size="l">
        {activeProjects.map((project) => (
          <ProjectStatus
            key={project.projectNumber}
            customerName={project.customerName}
            projectNumber={project.projectNumber}
            shipDate={project.shipDate}
            moldedGoal={project.moldedGoal}
            postmachinedGoal={project.postmachinedGoal}
            bondedGoal={project.bondedGoal}
            connectorizedGoal={project.connectorizedGoal}
            minDate={project.minDate}
          />
        ))}
      </SpaceBetween>
    </Container>
  );
}

export default Summary;
