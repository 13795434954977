/* eslint-disable react/no-unstable-nested-components */
import {
  Alert,
  AttributeEditor,
  Badge,
  Box,
  Button,
  Checkbox,
  Container,
  FormField,
  Grid,
  Header,
  Input,
  Modal,
  SpaceBetween,
  Table,
  TokenGroup,
} from '@cloudscape-design/components';
import { useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import toPath from 'lodash/toPath';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

const BASE_MATERIALS = [
  {
    id: 'pmma',
    display: 'PMMA',
    isDefault: false,
    cappingStyle: [
      {
        id: '250um-pmma',
        display: '250um PMMA cap (thermally bonded)',
        isDefault: true,
      },
    ],
  },
  {
    id: 'pc',
    display: 'PC',
    isDefault: false,
    cappingStyle: [
      {
        id: '175um-pc',
        display: '175um PC cap (thermally bonded)',
        isDefault: true,
      },
    ],
  },
  {
    id: 'cop',
    display: 'COP',
    isDefault: false,
    cappingStyle: [
      {
        id: '175um-coc-x1',
        display: '175um COC-X1 cap (recommended for most applications)',
        isDefault: true,
      },
      {
        id: '175um-coc',
        display: '175um COC cap (recommended for high-temp applications)',
        isDefault: false,
      },
    ],
  },
];

function EditMaterialsModal({ visible, close }) {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const { materials: currentMaterials } = values;
  const [materials, setMaterials] = useState([]);
  useEffect(() => {
    setMaterials(cloneDeep(currentMaterials));
  }, [currentMaterials, visible]);
  const [fieldErrors, setFieldErrors] = useState();
  const [bannerError, setBannerError] = useState();
  useEffect(() => {
    setFieldErrors(materials.map(() => ({})));
    setBannerError(undefined);
  }, [materials]);

  return (
    <Modal
      header={<Header>Edit materials</Header>}
      visible={visible}
      onDismiss={close}
      size="max"
      footer={(
        <Box float="right">
          <SpaceBetween size="xs" direction="horizontal">
            <Button onClick={close}>
              Cancel
            </Button>
            <Button onClick={() => {
              // Check that at least one material is present
              if (materials.length < 1) {
                setBannerError('At least 1 material is required');
                return;
              }

              // Validate the materials
              try {
                const schema = Yup.array().of(Yup.object().shape({
                  display: Yup.string().required('Display name is required'),
                  id: Yup.string().required('Internal ID is required'),
                  cappingStyle: Yup.array().of(Yup.object().shape({
                    display: Yup.string().required('Display name is required'),
                    id: Yup.string().required('Internal ID is required'),
                    isDefault: Yup.boolean(),
                  })),
                  isDefault: Yup.boolean(),
                })).required();
                schema.validateSync(materials, { abortEarly: false });
              } catch ({ inner }) {
                const newErrors = materials.map(() => ({}));
                inner.forEach(({ path, message }) => {
                  const pathArray = toPath(path);
                  newErrors[pathArray[0]][pathArray[1]] = message;
                });
                setFieldErrors(newErrors);
                return;
              }

              // Check that at least one material is default
              // Otherwise, mark the first material as default
              if (!materials.some((material) => material.isDefault)) {
                materials[0].isDefault = true;
              }

              // Update the formik values and close the modal
              setFieldValue('materials', materials);
              setFieldTouched('materials', true);
              close();
            }}
            >
              Update
            </Button>
          </SpaceBetween>
        </Box>
      )}
    >
      <SpaceBetween size="xl" direction="vertical">
        {bannerError ? (
          <Alert type="error">{bannerError}</Alert>
        ) : null}
        <AttributeEditor
          onAddButtonClick={() => setMaterials((oldMaterials) => [...oldMaterials, {
            id: '',
            display: '',
            cappingStyle: [],
            isDefault: false,
          }])}
          onRemoveButtonClick={({
            detail: { itemIndex },
          }) => {
            setMaterials((oldMaterials) => {
              const tmpCurrentMaterials = [...oldMaterials];
              tmpCurrentMaterials.splice(itemIndex, 1);
              return tmpCurrentMaterials;
            });
          }}
          items={materials}
          addButtonText="Add new material"
          definition={[
            {
              label: 'Display',
              control: (item, index) => (
                <FormField errorText={fieldErrors[index]?.display}>
                  <Input
                    value={item.display}
                    placeholder="Enter material name"
                    onChange={({ detail }) => {
                      setMaterials((oldMaterials) => {
                        const newMaterials = [...oldMaterials];
                        newMaterials[index].display = detail.value;
                        newMaterials[index].id = detail.value.toLowerCase().replace(/[^a-z0-9]+/g, '-');
                        return newMaterials;
                      });
                    }}
                  />
                </FormField>
              ),
            },
            {
              label: 'Internal ID',
              control: (item, index) => (
                <FormField errorText={fieldErrors[index]?.id}>
                  <Input
                    value={item.id}
                    placeholder="Enter internal ID (updates automatically when display is changed)"
                    onChange={({ detail }) => {
                      setMaterials((oldMaterials) => {
                        const newMaterials = [...oldMaterials];
                        newMaterials[index].id = detail.value;
                        return newMaterials;
                      });
                    }}
                  />
                </FormField>
              ),
            },
            {
              label: 'Capping option',
              control: (item, index) => {
                const [cappingStyleInput, setCappingStyleInput] = useState('');
                return (
                  <Grid gridDefinition={[{ colspan: 12 }, { colspan: 12 }]} disableGutters>
                    <FormField>
                      <Input
                        placeholder='Enter new option and press "Enter"'
                        onChange={({ detail }) => setCappingStyleInput(detail.value)}
                        value={cappingStyleInput}
                        onKeyDown={(e) => {
                          if (e?.detail?.key === 'Enter') {
                            if (cappingStyleInput.length > 0) {
                              setMaterials((oldMaterials) => {
                                const newMaterials = [...oldMaterials];
                                newMaterials[index].cappingStyle = [
                                  ...newMaterials[index].cappingStyle,
                                  {
                                    display: cappingStyleInput,
                                    id: cappingStyleInput.toLowerCase().replace(/[^a-z0-9]+/g, '-'),
                                    isDefault: false,
                                  },
                                ];
                                return newMaterials;
                              });
                            }
                            setCappingStyleInput('');
                          }
                        }}
                      />
                    </FormField>
                    <TokenGroup
                      onDismiss={({ detail: { itemIndex } }) => {
                        setMaterials(
                          (oldMaterials) => oldMaterials.map((material, materialIndex) => {
                            if (materialIndex === index) {
                              return {
                                ...material,
                                cappingStyle: [
                                  ...material.cappingStyle.slice(0, itemIndex),
                                  ...material.cappingStyle.slice(itemIndex + 1),
                                ],
                              };
                            }
                            return material;
                          }),
                        );
                      }}
                      items={item.cappingStyle.map((cappingStyle) => {
                        const { display, isDefault } = cappingStyle;
                        return {
                          label: display,
                          description: (
                            <Checkbox
                              checked={isDefault}
                              onChange={({ detail }) => {
                                setMaterials((oldMaterials) => {
                                  const newMaterials = [...oldMaterials];
                                  newMaterials[index].cappingStyle = newMaterials[index]
                                    .cappingStyle.map(
                                      (cappingStyleItem) => {
                                        if (cappingStyleItem.display === display) {
                                          return { ...cappingStyleItem, isDefault: detail.checked };
                                        } if (detail.checked) {
                                          return { ...cappingStyleItem, isDefault: false };
                                        }
                                        return cappingStyleItem;
                                      },
                                    );
                                  return newMaterials;
                                });
                              }}
                            >
                              Default
                            </Checkbox>
                          ),
                        };
                      })}
                    />
                  </Grid>
                );
              },
            },
          ]}
        />
      </SpaceBetween>
    </Modal>
  );
}

EditMaterialsModal.propTypes = {
  visible: PropTypes.bool,
  close: PropTypes.func.isRequired,
};

EditMaterialsModal.defaultProps = {
  visible: false,
};

function MaterialsTable({ loading }) {
  const { values, setFieldValue } = useFormikContext();
  const { materials } = values;

  const [editMaterialsModalOpen, setEditMaterialsModalOpen] = useState(false);

  return (
    <>
      <EditMaterialsModal
        visible={editMaterialsModalOpen}
        close={() => { setEditMaterialsModalOpen(false); }}
      />
      <Container fitHeight>
        <Table
          variant="embedded"
          header={(
            <Header
              variant="h2"
              actions={(
                <Button
                  onClick={() => { setEditMaterialsModalOpen(true); }}
                >
                  Edit
                </Button>
          )}
            >
              Materials

            </Header>
      )}
          loading={loading}
          loadingText="Loading material info..."
          columnDefinitions={[
            {
              id: 'isDefault',
              header: 'Default',
              cell: (row) => (
                <Checkbox
                  checked={row.isDefault}
                  onChange={({ detail }) => {
                    setFieldValue('materials', materials.map((material) => {
                      if (!detail.checked) {
                        return { ...material };
                      }
                      if (material.id === row.id) {
                        return { ...material, isDefault: detail.checked };
                      }
                      return { ...material, isDefault: false };
                    }));
                  }}
                />
              ),
            },
            {
              id: 'display',
              header: 'Material',
              cell: (row) => row.display,
            },
            {
              id: 'id',
              header: 'Internal ID',
              cell: (row) => row.id,
            },
            {
              id: 'cappingStyle',
              header: 'Capping options',
              cell: (row) => (
                <SpaceBetween size="xxs">
                  {(row?.cappingStyle || []).map(({ display, id, isDefault }) => (
                    <Badge key={id} color={isDefault ? 'green' : 'grey'}>{display}</Badge>
                  ))}
                </SpaceBetween>
              ),
            },

          ]}
          items={materials}
        />
      </Container>
    </>
  );
}

MaterialsTable.propTypes = {
  loading: PropTypes.bool,
};

MaterialsTable.defaultProps = {
  loading: false,
};

export default MaterialsTable;

export { EditMaterialsModal, BASE_MATERIALS };
