import {
  Alert,
  Box,
  Button,
  ColumnLayout,
  Container,
  ContentLayout,
  Grid,
  Header,
  SpaceBetween,
  StatusIndicator,
} from '@cloudscape-design/components';
import {
  getDownloadURL,
  getStorage,
  listAll,
  ref,
} from 'firebase/storage';
import React from 'react';
import { useQuery } from 'react-query';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';

import CADViewer from '../../components/CADPreview/viewer';
import FeedbackSummaryTable from '../../components/Feedback';
import PricingSummaryTable from '../../components/NewPricing';
import QuoteStatus from '../../components/QuoteStatus';
import QuoteStatusTag from '../../components/QuoteStatusTag';
import TransferPart from '../../components/TransferPart';
import { usePartAndQuote } from '../../features/firebase';

function Part() {
  const { partId } = useParams();
  const { part, quote } = usePartAndQuote(partId);
  const {
    material,
    filename,
    cappingStyle,
    cappingOptions,
    quantity,
    optionalNotes,
  } = part;
  const {
    feedback,
    optionalFeedback,
    feedbackChecklistReviewed,
    status,
  } = quote;

  const showFeedback = feedbackChecklistReviewed || status !== 'pending';

  const { data: cadPreviewSrc, status: cadPreviewStatus } = useQuery(
    ['cadPreview', part.uid, part.fileID],
    async () => {
      const storage = getStorage();
      const storageRef = ref(storage, `${part.uid}/cad/${part.fileID}.glb`);
      const url = await getDownloadURL(storageRef);
      return url;
    },
    {
      enabled: !!part.uid && !!part.fileID,
    },
  );

  const { data: cadDownloadSrc, status: cadDownloadStatus } = useQuery(
    ['cadDownload', part.uid, part.fileID],
    async () => {
      const storage = getStorage();
      const uploadsRef = ref(storage, `/user-uploads/cad/${part.uid}`);
      const { items: uploads } = await listAll(uploadsRef);
      const fileMatches = [];
      uploads.forEach((upload) => {
        if (upload.fullPath.includes(part.fileID)) {
          fileMatches.push(upload.fullPath);
        }
      });

      // no matches
      if (fileMatches.length === 0) {
        console.error('Could not find file', { partId, part });
        throw new Error('Could not find file');
        // too many matches
      } if (fileMatches.length > 1) {
        console.error('Could not find the correct file', { partId, part });
        throw new Error('Could not find the correct file');
      }

      // found one match! get the download link
      const url = await getDownloadURL(ref(storage, fileMatches[0]));
      return url;
    },
    {
      enabled: !!part.uid && !!part.fileID,
    },
  );

  const navigate = useNavigate();

  if (!part) {
    return (
      <ContentLayout header={<Header variant="h1">Loading...</Header>} />
    );
  }

  return (
    <ContentLayout
      header={(
        <SpaceBetween size="m">
          <Header
            variant="h1"
            description={<QuoteStatusTag partId={partId} />}
            actions={(
              <SpaceBetween direction="horizontal" size="xs">
                <QuoteStatus partId={partId} />
                <Button
                  iconName="external"
                  variant="normal"
                  href={`https://parallelfluidics.com/parts/view?quote=${partId}`}
                  target="_blank"
                >
                  View live quote
                </Button>
                <TransferPart partId={partId} />
              </SpaceBetween>
            )}
          >
            {`Quote #${partId.substring(0, 6).toUpperCase()}`}
          </Header>
          {part.email ? (
            <Alert statusIconAriaLabel="Info">
              {`The user submitted this RFQ without an account and indicated that their email is ${part.email}. When Parascope sends an email to this user, it will contain an account creation link where they will be prompted to set a password. If they need a new link, you can generate one on their account page (click their email in the breadcrumb menu above).`}
            </Alert>
          ) : null}
        </SpaceBetween>
        )}
    >
      <Grid gridDefinition={[
        { colspan: { default: 12, s: 8 } }, { colspan: { default: 12, s: 4 } },
        { colspan: 12 },
        { colspan: 12 },
      ]}
      >
        <Container header={<Header variant="h2">Original RFQ Info</Header>} fitHeight>
          <SpaceBetween size="s">
            <ColumnLayout columns={2} variant="text-grid">
              <div>
                <Box variant="awsui-key-label">Filename</Box>
                <div>{filename}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Material</Box>
                <div>{material}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Capping style</Box>
                <div>
                  {`${cappingStyle} / ${cappingOptions}`}
                </div>
              </div>
              <div>
                <Box variant="awsui-key-label">Quantity</Box>
                <div>{quantity}</div>
              </div>
            </ColumnLayout>
            {optionalNotes && optionalNotes.length > 0 ? (
              <Box>
                <span style={{ fontWeight: 800 }}>Notes: </span>
                {optionalNotes}
              </Box>
            ) : null}
          </SpaceBetween>
        </Container>
        <Container
          header={(
            <Header
              variant="h2"
              actions={(
                <SpaceBetween direction="horizontal" size="xs">
                  <Button onClick={() => { navigate(`/part/${partId}/cad`); }}>
                    Edit
                  </Button>
                  <Button
                    loading={cadDownloadStatus === 'loading'}
                    disabled={cadDownloadStatus !== 'success' || !cadDownloadSrc}
                    variant="primary"
                    href={cadDownloadSrc}
                    download
                  >
                    Download
                  </Button>
                </SpaceBetween>
            )}
            >
              CAD Preview
            </Header>
          )}
          fitHeight
          disableContentPaddings
        >
          {cadPreviewStatus === 'loading' ? (
            <Box textAlign="center" margin="m">
              <StatusIndicator type="loading">Loading...</StatusIndicator>
            </Box>
          ) : null}
          {cadPreviewStatus === 'error' ? (
            <Box textAlign="center" margin="m">
              <StatusIndicator type="error">Error loading CAD preview</StatusIndicator>
            </Box>
          ) : null}
          {cadPreviewStatus === 'success' && cadPreviewSrc ? (
            <CADViewer src={cadPreviewSrc} style={{ height: 260, width: '100%' }} />
          ) : null}
        </Container>
        <FeedbackSummaryTable
          header={(
            <Header
              variant="h2"
              actions={showFeedback ? (
                <Button variant="normal" onClick={() => { navigate(`/part/${partId}/feedback`); }}>Edit</Button>
              ) : null}
            >
              Feedback
            </Header>
          )}
          feedback={feedback}
          optionalFeedback={optionalFeedback}
          forceEmpty={!showFeedback}
          empty={(
            <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
              <SpaceBetween size="m">
                <b>Design needs review</b>
                <Button onClick={() => { navigate('./feedback'); }}>Review</Button>
              </SpaceBetween>
            </Box>
          )}
        />
        <PricingSummaryTable partId={partId} />
      </Grid>
    </ContentLayout>
  );
}

export default Part;
