import {
  Box,
  Container,
  ContentLayout,
  Header,
  SpaceBetween,
  Spinner,
} from '@cloudscape-design/components';
import React, {
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';

import PLCButton from '../../components/HMI/components/plcButton';
import HMIContextProvider, { handlify, usePLCAddress } from '../../components/HMI/hooks/context';
import NotificationsContext from '../../features/notifications';
import PLCContextProvider, {
  usePLCError,
  usePLCReady,
  usePLCValue,
} from '../../features/plc/context';
import BondingHMI from './bonding';
import FormingHMI from './forming';

const PressMode = Object.freeze({
  None: 0,
  Forming: 1,
  Bonding: 2,
});

function HMIRouter() {
  const { pressName } = useParams();
  const pressTitle = useMemo(() => ({
    press1: 'Press 1',
    press2: 'Press 2',
    press3: 'Press 3',
    press4: 'Press 4',
  }[pressName]), [pressName]);

  const pressHandle = useMemo(() => handlify(pressName), [pressName]);

  const pressModeHandle = useMemo(() => `MAIN.${pressHandle}_Mode`, [pressHandle]);
  const mode = usePLCValue(pressModeHandle);
  const error = usePLCError();
  const ready = usePLCReady();

  const { addNotification } = useContext(NotificationsContext);
  useEffect(() => {
    if (error) {
      addNotification({
        header: 'Error connecting to PLC',
        type: 'error',
        content: error,
        dismissible: true,
      });
    }
  }, [error]);

  if (!ready || error) {
    return <Box textAlign="center"><Spinner size="large" /></Box>;
  }
  switch (mode) {
    case undefined: {
      // Sometimes, `ready` is available before other PLC values
      // I think this is because of the way useSyncExternalStore updates values
      return <Box textAlign="center"><Spinner size="large" /></Box>;
    }
    case PressMode.Forming: {
      return (
        <HMIContextProvider press={pressName} process="forming">
          <FormingHMI />
        </HMIContextProvider>
      );
    }
    case PressMode.Bonding: {
      return (
        <HMIContextProvider press={pressName} process="bonding">
          <BondingHMI />
        </HMIContextProvider>
      );
    }
    default: {
      return (
        <ContentLayout header={<Header variant="h1">{pressTitle}</Header>}>
          <Container>
            <SpaceBetween size="l">
              Select the mode of operation for this press.
              <PLCButton
                onClick={(write) => write({ handle: pressModeHandle, value: 1 })}
                style={{ marginRight: '1rem' }}
              >
                Forming Mode
              </PLCButton>
              <PLCButton
                onClick={(write) => write({ handle: pressModeHandle, value: 2 })}
              >
                Bonding Mode
              </PLCButton>
            </SpaceBetween>
          </Container>
        </ContentLayout>
      );
    }
  }
}

function HMI() {
  const { pressName } = useParams();
  const plcAddress = usePLCAddress(pressName);

  const handles = useMemo(() => {
    const pressHandle = handlify(pressName);
    return [
      { name: `MAIN.${pressHandle}_Mode`, type: 'INT' },
      { name: `MAIN.${pressHandle}_Bonding_Orientation`, type: 'INT' },
    ];
  }, [pressName]);

  return (
    <PLCContextProvider url={plcAddress} handles={handles}>
      <HMIRouter />
    </PLCContextProvider>
  );
}

export default HMI;
