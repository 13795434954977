import {
  collection,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import { useMemo } from 'react';
import {
  useFirestoreCollectionData,
} from 'reactfire';

const useActiveProjects = (limit) => {
  const { data: activeOrders } = useFirestoreCollectionData(query(
    collection(getFirestore(), 'orders'),
    where('status', '==', 'In progress'),
  ));
  return useMemo(() => {
    const projects = [];
    (activeOrders || []).forEach((order) => {
      (order?.shipments || []).forEach((shipment) => {
        (shipment?.lineItems || []).forEach((lineItem) => {
          projects.push({
            customerName: order.customer,
            projectNumber: lineItem.projectId,
            shipDate: shipment.targetShipDate,
            moldedGoal: lineItem.moldedGoal,
            postmachinedGoal: lineItem.postmachinedGoal,
            bondedGoal: lineItem.bondedGoal,
            connectorizedGoal: lineItem.connectorizedGoal,
            minDate: lineItem.minDate,
            notes: lineItem.notes,
          });
        });
      });
    });
    projects.sort((a, b) => new Date(a.shipDate).getTime() - new Date(b.shipDate).getTime());
    return projects.slice(0, limit);
  }, [activeOrders]);
};

const useShippedProjects = () => {
  const { data: activeOrders } = useFirestoreCollectionData(query(
    collection(getFirestore(), 'orders'),
    where('status', '==', 'Shipped'),
  ));
  return useMemo(() => {
    const projects = [];
    (activeOrders || []).forEach((order) => {
      (order?.shipments || []).forEach((shipment) => {
        (shipment?.lineItems || []).forEach((lineItem) => {
          projects.push({
            customerName: order.customer,
            projectNumber: lineItem.projectId,
            shipDate: shipment.targetShipDate,
            moldedGoal: lineItem.moldedGoal,
            postmachinedGoal: lineItem.postmachinedGoal,
            bondedGoal: lineItem.bondedGoal,
            connectorizedGoal: lineItem.connectorizedGoal,
          });
        });
      });
    });
    projects.sort((a, b) => new Date(a.shipDate).getTime() - new Date(b.shipDate).getTime());
    return projects.filter((project) => project.moldedGoal > 0);
  }, [activeOrders]);
};

const useProjectStatus = (projectNumber, minDate) => {
  const memoizedQuery = useMemo(() => {
    if (minDate) {
      return query(
        collection(getFirestore(), 'travelers'),
        where('project', '==', projectNumber),
        where('_createdBy.timestamp', '>', new Date(minDate)),
      );
    }
    return query(
      collection(getFirestore(), 'travelers'),
      where('project', '==', projectNumber),
    );
  }, [projectNumber, minDate]);

  const { status: queryStatus, data: travelers } = useFirestoreCollectionData(memoizedQuery, { idField: 'id' });
  const projectStatus = useMemo(() => {
    if (queryStatus === 'success') {
      const retParts = {
        molded: {
          inspected: 0,
          toBeInspected: 0,
          rejectCosmetic: 0,
          rejectMajor: 0,
          tuning: 0,
          unknown: 0,
        },
        postmachined: {
          done: 0,
          todo: 0,
        },
        bonded: {
          inspected: 0,
          toBeInspected: 0,
          rejectCosmetic: 0,
          rejectMajor: 0,
          tuning: 0,
          unknown: 0,
        },
        connectorized: {
          done: 0,
          todo: 0,
        },
      };
      travelers.forEach((traveler) => {
        if (!traveler.moldRunId || traveler.moldRunId.length === 0) {
          return;
        }
        switch (traveler.moldedPartInspected) {
          case 'Not inspected': { retParts.molded.toBeInspected += 1; return; }
          case 'Passed': { retParts.molded.inspected += 1; break; }
          case 'Reject: Cosmetic': { retParts.molded.rejectCosmetic += 1; return; }
          case 'Reject: Major': { retParts.molded.rejectMajor += 1; return; }
          case 'Tuning': { retParts.molded.tuning += 1; return; }
          default: { retParts.molded.unknown += 1; return; }
        }
        if (traveler.secondariesMachined) {
          retParts.postmachined.done += 1;
        }
        if (!traveler.bondRunId || traveler.bondRunId.length === 0) {
          return;
        }
        switch (traveler.bondedPartInspected) {
          case 'Not inspected': { retParts.bonded.toBeInspected += 1; return; }
          case 'Passed': { retParts.bonded.inspected += 1; break; }
          case 'Reject: Cosmetic': { retParts.bonded.rejectCosmetic += 1; return; }
          case 'Reject: Major': { retParts.bonded.rejectMajor += 1; return; }
          case 'Tuning': { retParts.molded.tuning += 1; return; }
          default: { retParts.bonded.unknown += 1; return; }
        }
        if (traveler.componentsWelded) {
          retParts.connectorized.done += 1;
        }
      });
      retParts.postmachined.todo = retParts.molded.inspected - retParts.postmachined.done;
      retParts.connectorized.todo = retParts.bonded.inspected - retParts.connectorized.done;
      return retParts;
    }
    return undefined;
  }, [travelers, queryStatus]);
  return { projectStatus, queryStatus };
};

const useShipDate = (shipDate) => useMemo(() => {
  const daysUnitlShip = Math.ceil((new Date(`${shipDate} 00:00:00`) - new Date()) / (1000 * 60 * 60 * 24));
  if (daysUnitlShip === 1) {
    return 'Ships Tomorrow';
  }
  if (daysUnitlShip === 0) {
    return 'Ships Today';
  }
  return `Ships in ${daysUnitlShip} Days`;
}, [shipDate]);

export {
  useActiveProjects,
  useShippedProjects,
  useShipDate,
  useProjectStatus,
};
