import PropTypes from 'prop-types';
import React, {
  createContext,
  useMemo,
  useState,
} from 'react';
import {
  useLocation,
} from 'react-router-dom';

const NotificationsContext = createContext();

function NotificationsProvider({ children }) {
  const location = useLocation();

  const [notifications, setNotifications] = useState([]);
  const removeNotification = (id) => setNotifications(
    (prevNotifications) => prevNotifications.filter((n) => n.id !== id),
  );
  const addNotification = (notification) => {
    const newId = notification.id || `message_${Math.random().toString(36)}`;
    const newNotification = {
      ...notification,
      id: newId,
      onDismiss: () => { removeNotification(newId); },
      location: location.pathname,
    };
    setNotifications((prevNotifications) => [...prevNotifications, newNotification]);
  };

  const value = useMemo(() => ({
    notifications,
    addNotification,
    removeNotification,
  }), [notifications]);

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
}

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationsContext;
export { NotificationsProvider };
