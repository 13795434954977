import {
  Button,
} from '@cloudscape-design/components';
import React, {
  useContext,
  useEffect,
  useMemo,
} from 'react';

import NotificationsContext from '../../../features/notifications';
import {
  usePLCValue,
  usePLCWrite,
} from '../../../features/plc/context';
import { useHFTSHandle, usePressTitle, useProgramHandle } from '../hooks/context';
import parseTime from './parseTime';

function useShutdownWarning() {
  const { addNotification, removeNotification } = useContext(NotificationsContext);

  const programHandle = useProgramHandle();
  const shutdownTimerElapsed = usePLCValue(`${programHandle}.tShutdownTimer.ET`);
  const shutdownTimerSetpoint = usePLCValue(`${programHandle}.tShutdownTimer.PT`);

  useEffect(() => {
    if (shutdownTimerElapsed > shutdownTimerSetpoint - 5 * 60 * 1000) {
      addNotification({
        type: 'error',
        dismissible: false,
        content: `Heaters shutting off soon. No automation cycles have been run for ${parseTime(shutdownTimerElapsed)}. Please run a cycle or the heaters will shut off in ${parseTime(shutdownTimerSetpoint - shutdownTimerElapsed)}.`,
        id: `${programHandle}.tShutdownTimer`,
      });
    } else {
      removeNotification(`${programHandle}.tShutdownTimer`);
    }
  }, [shutdownTimerElapsed, shutdownTimerSetpoint]);
}

function useThermocoupleWarning() {
  const { addNotification, removeNotification } = useContext(NotificationsContext);
  const programHandle = useProgramHandle();
  const pressTitle = usePressTitle();
  const checkThermocouple = usePLCValue(`${programHandle}.bCheckThermocouple`);
  const write = usePLCWrite();
  useEffect(() => {
    if (checkThermocouple) {
      addNotification({
        type: 'error',
        dismissible: false,
        content: `The tool on ${pressTitle} should be heating, but is still reading below 40°. Check that the thermocouple is installed. The heaters have been shut off.`,
        id: `${programHandle}.bCheckThermocouple`,
        action: <Button onClick={() => { write({ handle: `${programHandle}.bCheckThermocouple`, value: false }); }}>Reset thermocouple check</Button>,
      });
    } else {
      removeNotification(`${programHandle}.bCheckThermocouple`);
    }
  }, [checkThermocouple]);
}

function useTooHotWarning() {
  const { addNotification, removeNotification } = useContext(NotificationsContext);

  const programHandle = useProgramHandle();

  const upperHFTSHandle = useHFTSHandle('Upper');
  const lowerHFTSHandle = useHFTSHandle('Lower');
  const capSideHFTSHandle = useHFTSHandle('CapSide');
  const chipSideHFTSHandle = useHFTSHandle('ChipSide');

  const [side1HFTSHandle, side2HFTSHandle, side1Title, side2Title] = useMemo(() => {
    if (programHandle.includes('Forming')) {
      return [upperHFTSHandle, lowerHFTSHandle, 'Upper', 'Lower'];
    }
    return [capSideHFTSHandle, chipSideHFTSHandle, 'Cap Side', 'Chip Side'];
  }, [programHandle, upperHFTSHandle, lowerHFTSHandle, capSideHFTSHandle, chipSideHFTSHandle]);

  const side1TooHot = usePLCValue(`${side1HFTSHandle}.bTooHot`);
  const side2TooHot = usePLCValue(`${side2HFTSHandle}.bTooHot`);

  const side1HP1TooHot = usePLCValue(`${side1HFTSHandle}.bHP1TooHot`);
  const side1HP2TooHot = usePLCValue(`${side1HFTSHandle}.bHP2TooHot`);
  const side1HP3TooHot = usePLCValue(`${side1HFTSHandle}.bHP3TooHot`);
  const side1HP4TooHot = usePLCValue(`${side1HFTSHandle}.bHP4TooHot`);
  const side1HP5TooHot = usePLCValue(`${side1HFTSHandle}.bHP5TooHot`);
  const side1HP6TooHot = usePLCValue(`${side1HFTSHandle}.bHP6TooHot`);
  const side1ToolTooHot = usePLCValue(`${side1HFTSHandle}.bToolTooHot`);
  const side1HotBlockTooHot = usePLCValue(`${side1HFTSHandle}.bHotBlockTooHot`);
  const side1FrontBlockTooHot = usePLCValue(`${side1HFTSHandle}.bFrontBlockTooHot`);
  const side1RearBlockTooHot = usePLCValue(`${side1HFTSHandle}.bRearBlockTooHot`);
  const side2HP1TooHot = usePLCValue(`${side2HFTSHandle}.bHP1TooHot`);
  const side2HP2TooHot = usePLCValue(`${side2HFTSHandle}.bHP2TooHot`);
  const side2HP3TooHot = usePLCValue(`${side2HFTSHandle}.bHP3TooHot`);
  const side2HP4TooHot = usePLCValue(`${side2HFTSHandle}.bHP4TooHot`);
  const side2HP5TooHot = usePLCValue(`${side2HFTSHandle}.bHP5TooHot`);
  const side2HP6TooHot = usePLCValue(`${side2HFTSHandle}.bHP6TooHot`);
  const side2ToolTooHot = usePLCValue(`${side2HFTSHandle}.bToolTooHot`);
  const side2HotBlockTooHot = usePLCValue(`${side2HFTSHandle}.bHotBlockTooHot`);
  const side2FrontBlockTooHot = usePLCValue(`${side2HFTSHandle}.bFrontBlockTooHot`);
  const side2RearBlockTooHot = usePLCValue(`${side2HFTSHandle}.bRearBlockTooHot`);

  useEffect(() => {
    if (side2TooHot || side1TooHot) {
      addNotification({
        type: 'error',
        dismissible: false,
        content: `The following got too hot! The system shut itself down to prevent damage: ${[
          { name: `${side1Title} Heatpipe 1`, value: side1HP1TooHot },
          { name: `${side1Title} Heatpipe 2`, value: side1HP2TooHot },
          { name: `${side1Title} Heatpipe 3`, value: side1HP3TooHot },
          { name: `${side1Title} Heatpipe 4`, value: side1HP4TooHot },
          { name: `${side1Title} Heatpipe 5`, value: side1HP5TooHot },
          { name: `${side1Title} Heatpipe 6`, value: side1HP6TooHot },
          { name: `${side1Title} Tool`, value: side1ToolTooHot },
          { name: `${side1Title} Hot Block`, value: side1HotBlockTooHot },
          { name: `${side1Title} Front Block`, value: side1FrontBlockTooHot },
          { name: `${side1Title} Rear Block`, value: side1RearBlockTooHot },

          { name: `${side2Title} Heatpipe 1`, value: side2HP1TooHot },
          { name: `${side2Title} Heatpipe 2`, value: side2HP2TooHot },
          { name: `${side2Title} Heatpipe 3`, value: side2HP3TooHot },
          { name: `${side2Title} Heatpipe 4`, value: side2HP4TooHot },
          { name: `${side2Title} Heatpipe 5`, value: side2HP5TooHot },
          { name: `${side2Title} Heatpipe 6`, value: side2HP6TooHot },
          { name: `${side2Title} Tool`, value: side2ToolTooHot },
          { name: `${side2Title} Hot Block`, value: side2HotBlockTooHot },
          { name: `${side2Title} Front Block`, value: side2FrontBlockTooHot },
          { name: `${side2Title} Rear Block`, value: side2RearBlockTooHot },
        ].filter((handle) => handle.value).map((handle) => handle.name).join(', ')}`,
        id: `${side2HFTSHandle}.bTooHot-${side1HFTSHandle}.bTooHot`,
      });
    } else {
      removeNotification(`${side2HFTSHandle}.bTooHot-${side1HFTSHandle}.bTooHot`);
    }
  }, [
    side2TooHot,
    side1TooHot,
    side2HP1TooHot,
    side2HP2TooHot,
    side2HP3TooHot,
    side2HP4TooHot,
    side2HP5TooHot,
    side2HP6TooHot,
    side2ToolTooHot,
    side2HotBlockTooHot,
    side2FrontBlockTooHot,
    side2RearBlockTooHot,
    side1HP1TooHot,
    side1HP2TooHot,
    side1HP3TooHot,
    side1HP4TooHot,
    side1HP5TooHot,
    side1HP6TooHot,
    side1ToolTooHot,
    side1HotBlockTooHot,
    side1FrontBlockTooHot,
    side1RearBlockTooHot,
  ]);
}

function Warnings() {
  useThermocoupleWarning();
  useTooHotWarning();
  useShutdownWarning();
  return null;
}

export default Warnings;
